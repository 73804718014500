@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* Stilizacija tijela stranice */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-image: url('../img/bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; 
  overflow: hidden;
  font-family: 'Poppins', Arial, sans-serif;
}

h1, h2, h3 {
  color: #68abf8;
}

p {
  color: #fff;
}

.v-alignment {
  display: flex;
  align-items: center;
  gap: 10px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #00365e;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0353a4;
}

@media screen and (max-width: 1000px) {
  .main-content {
    display: none !important;
  }
  .mobile-message {
    display: block !important;
    text-align: center;
    margin: 10px;
    padding-top: 200px;
    color: white;
    justify-content: center;
  }
}

/* Buttons */
.primary-button {
  background-color: #00365e; 
  border: none;
  color: white; 
  padding: 10px 20px; 
  font-size: 16px; 
  cursor: pointer; 
  outline: none; 
  transition: background-color 0.3s ease; 
}

.primary-button:hover {
  background-color: #0353a4;
}

.action-button {
  background-color: transparent; 
  border: none;
  color: #68abf8; 
  padding: 10px 10px; 
  font-size: 20px; 
  cursor: pointer; 
  outline: none; 
  transition: color 0.3s ease; 
}

.action-button:hover {
  color: #1488df; 
}

.action-button-2 {
  background-color: transparent; 
  border: none;
  color: #00365e; 
  padding: 10px 10px; 
  font-size: 16px; 
  cursor: pointer; 
  outline: none; 
  transition: color 0.3s ease; 
}

.action-button-2:hover {
  color: #0353a4; 
}

.action-button-3 {
  display: flex;
  background-color: transparent;
  border-radius: 10px;
  border: none;
  color: #fff; 
  padding: 10px 10px; 
  gap: 10px;
  font-size: 1.1vw; 
  justify-content: center;
  cursor: pointer; 
  outline: none; 
  transition: background-color 0.3s ease; 
}

.action-button-3:hover {
  background-color: rgba(104, 171, 248, 0.295); 
}

.community-menu-btn {
  display: flex;
  background-color: #7d8597;
  border-radius: 50px;
  border: none;
  color: #fff; 
  padding: 5px 10px; 
  gap: 10px;
  font-size: 1.1vw; 
  justify-content: center;
  cursor: pointer; 
  outline: none; 
  transition: background-color 0.3s ease; 
}

.community-menu-btn:hover {
  background-color: rgba(104, 171, 248, 0.295); 
}

.community-menu-btn:focus {
  background-color: #00365e; 
}

.red-button {
  background-color: #660708; 
  border: none;
  color: white; 
  padding: 10px 20px; 
  font-size: 16px; 
  cursor: pointer; 
  outline: none; 
  transition: background-color 0.3s ease; 
}

.red-button:hover {
  background-color: #ba181b;
}

.close-button { 
  background-color: #1488df; 
  border: none;
  color: white; 
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 16px; 
  cursor: pointer; 
  outline: none; 
  transition: background-color 0.3s ease; 
}

.close-button:hover {
  background-color: #0353a4;
}

/* Footer */

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  height: 3vh;
  background-color: #161a1d;
  color: #7d8597;
  font-size: 1.5vh;
  padding-top: 7px;
  width: 100%;
}

.footer span {
  color: #68abf8;
}


/* Inputs */
input[type="text"] {
  background-color: #212529;
  border: none; 
  border-bottom: 2px solid #1488df; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 60%;
  transition: border 0.3s ease;
}

input[type="text"]:focus {
  border: 1px solid #1488df;
}

input[type="email"] {
  background-color: #212529;
  border: none; 
  border-bottom: 2px solid #1488df; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 60%;
  transition: border 0.3s ease;
}

input[type="email"]:focus {
  border: 1px solid #1488df;
}


input[type="password"] {
  background-color: #212529;
  border: none; 
  border-bottom: 2px solid #1488df; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 60%;
  transition: border 0.3s ease;
}

input[type="password"]:focus {
  border: 1px solid #1488df;
}

input[type="date"] {
  background-color: #212529;
  border: none; 
  font-family: 'Poppins', Arial, sans-serif;
  border-bottom: 2px solid transparent; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 80%;
  transition: background-color 0.3s ease;
}

input[type="date"]:focus {
  border: 1px solid #1488df;
}

input[type="datetime-local"] {
  background-color: #212529;
  font-family: 'Poppins', Arial, sans-serif;
  border: none; 
  border-bottom: 2px solid transparent; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 80%;
  transition: background-color 0.3s ease;
}

input[type="datetime-local"]:focus {
  border: 1px solid #1488df;
}

input[type="time"] {
  background-color: #212529;
  border: none; 
  border-bottom: 2px solid transparent; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 60%;
  transition: background-color 0.3s ease;
}

input[type="time"]:focus {
  border: 1px solid #1488df;
}

textarea {
  background-color: #212529;
  border: none; 
  font-family: 'Poppins', Arial, sans-serif;
  border-bottom: 2px solid #1488df; 
  color: white; 
  padding: 10px; 
  outline: none; 
  resize: none;
  width: 30vw;
  height: 10vh;
  transition: border 0.3s ease;
}

textarea:focus {
  border: 1px solid #1488df;
}

select {
  background-color: #212529;
  border: none; 
  font-family: 'Poppins', Arial, sans-serif;
  border-bottom: 2px solid transparent; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 60%;
  transition: background-color 0.3s ease;
}

select:focus {
  border: 1px solid #1488df;
}


/* Stilizacija navigacijske trake */
nav {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 20px; 
  display: flex; 
  align-items: center;
}

/* Stilizacija logoa */
.logo img {
  width: 8vw; 
}

.logo-big img {
  width: 20vw;
}

.menu {
  margin-left: auto;
}

/* Stilizacija stavki izbornika */
.menu ul {
  list-style-type: none; 
  margin: 0;
  padding: 0;
}

.menu ul li {
  display: inline; 
}

.menu ul li a {
  color: #fff;
  text-decoration: none; 
  padding: 8px 12px; 
}

nav ul li a:hover {
  background-color: rgba(104, 171, 248, 0.295); 
  border-radius: 4px;
}

.profile {
  display: flex; 
  align-items: center; 
  margin-left: 50px;
  cursor: pointer;
}

.profile img {
  width: 30px; 
  aspect-ratio: 1;
  border-radius: 50%; 
  border: 2px solid #1488df; 
  margin-left: 10px;
  object-fit: cover;
}

.profile span {
  color: #fff; 
}

.header {
  display: flex;
  margin: 20px;
}

.home-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 10px;
  height: 85vh;
}

.community-main {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  margin: 10px;
  height: 86.5vh;
}

.content {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 10px;
  
}

.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

 /* To do list */
.todo-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 10px;
  gap: 10px;
}

.todo-list p {
  color: #7d8597;
}

.todo-list input[type="text"] {
  width: 100%;
}

.todo-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.todo-item p {
  color: #888;
  text-decoration: line-through;
}

.list ul {
  list-style-type: none;
  color: #fff;
  width: 100%;
  margin-left: 0px;
}

.todo-left {
  width: 70%;
}

.list li {
  background-color: #212529;
  border: 1px solid #1488df;
  margin-top: 10px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease, border 0.3s ease;
}

.list li:hover {
  box-shadow: 2px 2px 4px rgba(34, 34, 34, 0.5);
  border: 1px solid #0353a4;
}

/* Agenda */

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
}

.modal-content form {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  gap: 10px;
  margin-bottom: 30px;
}

.modal-group {
  display: flex;
  gap: 20px;
}

.modal-footer {
  text-align: center; 
  position: absolute; 
  bottom: 0;
  left: 0; 
  right: 0; 
  padding: 10px; 
  border-top: 1px solid #222831; 
  color: #7d8597;
  font-size: 0.7vw;
}


/* Promjena kalendara */
.rbc-day-bg {
  border-left: 1px solid #212529 !important;
}

.rbc-month-row {
  border: 1px solid #212529 !important;
}

/* Promjena rubova vremenskih slotova */
.rbc-time-slot .rbc-date-cell {
  border: 1px solid #212529 !important; /* Sivi gornji rub */
}

/* Promjena rubova ćelija zaglavlja */
.rbc-header {
  border: 1px solid #212529 !important; /* Jači sivi donji rub */
}

.rbc-time-content {
  background-color: #212529;
  color: #fff;
}

.rbc-toolbar {
  color: white;
}

.rbc-header {
  background-color: #0353a4 !important;
  color: white !important; 
}

.rbc-today {
  background-color: #00365e !important;
}

.rbc-off-range-bg {
  background-color: #161a1d !important;
}

.rbc-calendar {
  background-color: #343a40; 
}

/* Promjena boje teksta datuma i vremena */
.rbc-time-header,
.rbc-date-cell {
  color: #7d8597;
}

/* Promjena boje rubova za ćelije unutar agende */
.rbc-agenda-table {
  border-collapse: collapse; /* Osigurava da se rubovi ćelija ne preklapaju */
}

.rbc-agenda-table .rbc-agenda-date-cell,
.rbc-agenda-table .rbc-agenda-time-cell,
.rbc-agenda-table .rbc-agenda-event-cell {
  border: 1px solid #222831; /* Primjenjuje plavu boju za rubove */
}

.rbc-calendar {
  background-color: transparent; /* Promjena boje ruba na crno */
}

/* Alternativno, ciljanje na rubove unutar mjesečnog prikaza */
.rbc-month-view {
  background-color: #343a40;
  border: 1px solid #222831; /* Promjena boje ruba na crno */
}

/* Stiliziranje gumba Today, Back, i Next */
.rbc-btn-group button {
  background-color: #00365e;
  color: white !important; 
  border: none;
  border-radius: 0;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.25s ease;
}

.rbc-btn-group button:hover {
  background-color: #0353a4;
}

.rbc-btn-group button:focus {
  background-color: #0353a4 !important;
}


/* Account */
.profile-modal {
  display: flex;
  align-items: flex-start;
}

.profile-image {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-right: 30px; 
  width: 23%; 
  border-right: 1px solid #7d8597; 
  padding-right: 30px; 
}

.profile-photo {
  position: relative;
  cursor: pointer;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  background-color: #000;
  border: 3px solid #1488df;
  color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 10px;
}

.profile-photo img {
  width: 100%;
  object-fit: cover;
}

.photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);  
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s ease;
  pointer-events: none; 
}

.profile-photo:hover .photo-overlay {
  opacity: 1;
}

.username {
  margin-bottom: 20px;
  color: #adb5bd;
}

.profile-details {
  flex-grow: 1;
  padding-left: 30px;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.detail input[type="text"] {
  background-color: #343a40;
  font-family: 'Poppins', Arial, sans-serif;
  border: none; 
  border-bottom: 2px solid #1488df; 
  color: white; 
  padding: 10px; 
  outline: none; 
  width: 100%;
  transition: border 0.3s ease;
}

.label {
  font-weight: bold;
  margin-right: 5px;
  color: #e9ecef;
}

.value {
  flex-grow: 1;
  color: #adb5bd;
}

hr {
  border: 1px solid #212529;
}

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  width: 20vw;
  background-color: rgba(190, 226, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.login p {
  text-align: center;
  width: 100%;
}

.login h1 {
  color: #00365e;
}

.login-menu {
  display: flex;
  width: 100%;
}

/* Community */

.group-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.group-info h3 {
  font-size: 1.5vw;
  margin: 0;
  padding: 0;
}

.person-list {
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.person-list ul, li {
  list-style: none;
  justify-content: center;
  align-items: center;
}

.person-list img {
  width: 40px;
  border-radius: 50%;
  aspect-ratio: 1;
  border-color: 2px solid #1488df;
}

.person-unselected {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
}

.person-unselected:hover {
  color: #7d8597;
}

.person-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
}










